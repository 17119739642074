<script setup lang="ts">
import { useStorage } from '@vueuse/core'
import * as Sentry from '@sentry/vue';
import { CabinetTopMenu, PathType } from '~/enums'
import Feedback from '~/components/feedback/Feedback.vue'
import TopVillageRequest from '~/components/layout/TopVillageRequest.vue'

const props = defineProps({
	open: {
		type: Boolean,
		default: false
	}
})

const emits = defineEmits([
	'update:open'
])

const { t } = useI18n({
	useScope: 'local'
})

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()

const selected = useStorage('topMenuState', CabinetTopMenu.Portal)
const isModalAuth = ref(false)
const isOpenFeedback = ref(false)

const ui = {
	wrapper: 'absolute md:hidden z-50 inset-0 pointer-events-none',
	container: 'z-50 w-max !translate-x-0 !left-0 !right-0 !w-full !translate-y-[68px] pointer-events-auto',
	trigger: '',
	rounded: 'rounded-none',
	ring: 'ring-0 focus:ring-0 outline-0',
	overlay: {
		base: 'absolute top-[68px] pointer-events-auto',
		background: 'bg-gray-600/40'
	},
	transition: {
		enterActiveClass: 'transition ease-in-out duration-200',
		enterFromClass: 'opacity-0 -translate-y-full',
		enterToClass: 'opacity-100 translate-y-0',
		leaveActiveClass: 'transition ease-in duration-150',
		leaveFromClass: 'opacity-100 translate-y-0',
		leaveToClass: 'opacity-0 -translate-y-full',
	}
}

const menu = {
	portal: [
		{
			label: t('menu.villages'),
			to: '/' + PathType.Villages,
			icon: ''
		},
		{
			label: t('menu.houses'),
			to: '/' + PathType.Buy + '-' + PathType.House,
			icon: ''
		},
		{
			label: t('menu.plots'),
			to: '/' + PathType.Buy + '-' + PathType.Plot,
			icon: ''
		},
		{
			label: t('menu.developers'),
			to: '/' + PathType.Developers,
			icon: ''
		},
		{
			label: t('menu.documents'),
			to: '/' + PathType.Documents,
			icon: ''
		},
		{
			label: t('menu.advertising'),
			to: '/advertising',
		},
		{
			label: t('menu.journal'),
			to: 'https://journal.totdom.com',
			target: '_blank'
		}
	],
	cabinet: [
		{
			label: t('menu.ad_create'),
			to: '/cabinet/ads/create',
			icon: 'i-heroicons-plus-16-solid',
			class: 'font-semibold'
		},
		{
			label: t('menu.ads'),
			to: '/cabinet/ads',
			icon: 'i-heroicons-book-open-16-solid'
		},
		{
			label: t('menu.companies'),
			to: '/cabinet/companies',
			icon: 'i-heroicons-user-group-solid'
		},
		// {
		// 	label: t('menu.documents'),
		// 	to: '/cabinet/documents',
		// 	icon: 'i-heroicons-clipboard-document-list-solid'
		// }
	]
}

const isOpen = computed({
	get: () => props.open,
	set: (value) => emits('update:open', value)
})

const isActiveMenuItem = (url: string) => route.path.startsWith(url)

const onSelect = (value: CabinetTopMenu) => {
	selected.value = value
}

const onClose = () => {
	isOpen.value = false
}

const onBalance = () => {
	onClose()
}

const onReport = () => {
	onClose()
	
	const client = Sentry.getClient<Sentry.BrowserClient>()
	const feedback = client?.getIntegration(Sentry.Feedback)
	feedback?.openDialog()
}

const onSignin = () => {
	onClose()
	
	isModalAuth.value = true
}

const onSigninComplete = async () => {
	isModalAuth.value = false
	
	await router.push('/cabinet')
}

const onLogout = async () => {
	onClose()
	
	await authStore.logout()
	await router.push('/')
}

const onOpenFeedback = () => {
	onClose()
	
	isOpenFeedback.value = true
}

</script>

<template>
	<UPopover
		v-model:open="isOpen"
		:popper="{ placement: 'bottom-end', strategy: 'absolute' }"
		:ui="ui"
		overlay
	>
		<template #panel>
			<div class="p-4 w-full">
				<div class="w-full justify-stretch flex text-teal-800 text-md font-medium">
					<button
						type="button"
						class="block flex-1 rounded-tl-lg rounded-bl-lg px-10 h-10"
						:class="[selected === CabinetTopMenu.Portal ? 'bg-teal-800/20' : 'bg-stone-50']"
						:aria-label="t('button.portal')"
						@click="onSelect(CabinetTopMenu.Portal)"
					>
						{{ t('button.portal') }}
					</button>
					<span class="bg-white w-px"></span>
					<button
						type="button"
						class="block flex-1 rounded-tr-lg rounded-br-lg px-10 h-10"
						:class="[selected === CabinetTopMenu.Cabinet ? 'bg-teal-800/20' : 'bg-stone-50']"
						:aria-label="t('button.cabinet')"
						@click="onSelect(CabinetTopMenu.Cabinet)"
					>
						{{ t('button.cabinet') }}
					</button>
				</div>
				<template
					v-if="selected === CabinetTopMenu.Portal"
				>
					<AdButtonCreate
						class="mt-4"
						text-class="md:w-min block md:hidden lg:block text-base xl:text-base !leading-tight"
						:on-modal="onClose"
						block
					/>
					<TopVillageRequest
						class="!mt-1.5 !text-base"
						leading-icon=""
					/>
					<div class="flex flex-col mt-4 gap-2 text-teal-800 text-base font-normal">
						<NuxtLink
							v-for="(item, index) in menu.portal"
							:key="index"
							:to="item.to"
							:target="item.target"
							:class="{
								'font-semibold': isActiveMenuItem(item.to),
							}"
							@click="onClose"
						>
							{{ item.label }}
						</NuxtLink >
						<UButton
							variant="link"
							size="xl"
							class="p-0"
							:label="t('button.feedback')"
							@click="onOpenFeedback"
						/>
					</div>
				</template>
				<template
					v-if="selected === CabinetTopMenu.Cabinet"
				>
					<UDivider class="my-4" />
					<template v-if="authStore.isLogged">
						<div v-if="false" class="flex items-center gap-4">
							<span class="text-base">
								{{ t('balance') }}
							</span>
							<div class="flex-1">
								<UButton
									variant="outline"
									:label="t('button.balance')"
									:aria-label="t('button.balance')"
									@click="onBalance"
									block
								/>
							</div>
						</div>
						<UDivider v-if="false" class="mt-4"/>
						<div class="flex flex-col gap-2 mt-4 text-teal-800 text-base font-normal">
							<NuxtLink
								v-for="(item, index) in menu.cabinet"
								:key="index"
								:to="item.to"
								:aria-label="item.label"
								:class="item.class"
								class="flex items-center gap-2"
								@click="onClose"
							>
								<UIcon
									v-if="item.icon"
									:name="item.icon"
									class="w-5 h-5"
								/>
								{{ item.label }}
							</NuxtLink >
						</div>
						<UDivider class="my-4" />
						<UButton
							variant="link"
							size="xl"
							class="flex items-center gap-2"
							:aria-label="t('button.report')"
							:ui="{
								block: 'w-full flex items-center justify-start',
								gap: {
									xl: 'gap-2'
								},
								padding: {
									xl: 'p-0'
								}
							}"
							@click="onReport"
							block
						>
							<UIcon
								name="i-heroicons-exclamation-triangle-16-solid"
								class="w-5 h-5"
							/>
							{{ t('button.report') }}
						</UButton >
						<UDivider class="my-4" />
						<UButton
							variant="link"
							size="xl"
							class="flex items-center gap-2"
							:aria-label="t('button.logout')"
							:ui="{
								block: 'w-full flex items-center justify-start',
								gap: {
									xl: 'gap-2'
								},
								padding: {
									xl: 'p-0'
								}
							}"
							@click="onLogout"
							block
						>
							<UIcon
								name="i-heroicons-arrow-left-start-on-rectangle-16-solid"
								class="w-5 h-5 rotate-180"
							/>
							{{ t('button.logout') }}
						</UButton >
					</template>
					<template v-else>
						<UButton
							variant="outline"
							:label="t('button.signin')"
							:aria-label="t('button.signin')"
							@click="onSignin"
							block
						/>
					</template>
				</template>
				</div>
		</template>
	</UPopover>
	<UiModal
		v-model="isModalAuth"
		:fullscreen="$isMobile() && !$isTablet()"
	>
		<AuthForm
			:callback="onSigninComplete"
		/>
	</UiModal>
	<Feedback
		v-model:is-open="isOpenFeedback"
	/>
</template>

<i18n lang="json">
{
	"ru": {
		"balance": "Баланс",
		"button": {
			"portal": "Портал",
			"cabinet": "Кабинет",
			"ad": "Разместить объявление",
			"feedback": "Быстрая связь",
			"report": "Сообщить о проблеме",
			"balance": "Пополнить",
			"signin": "Войти в кабинет",
			"logout": "Выйти"
		},
		"menu": {
			"villages": "Поселки",
			"houses": "Дома",
			"plots": "Участки",
			"townhouses": "Таунхаусы",
			"developers": "Застройщики",
			"journal": "Журнал",
			"ad_create": "Создать объявление",
			"ads": "Мои объявления",
			"companies": "Мои компании",
			"documents": "Документы",
			"advertising": "Реклама на портале"
		}
	}
}
</i18n>