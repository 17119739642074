<script setup lang="ts">
import { PathType } from '~/enums'

const { t } = useI18n({
	useScope: 'local'
})
</script>

<template>
	<div class="relative z-[40] bg-white pt-4 md:pt-16">
		<div
			class="grid grid-cols-2 md:grid-cols-4 justify-center px-4 md:px-8 lg:px-16 py-0 w-full text-base bg-gray-600 max-md:max-w-full"
		>
			<div class="max-md:col-span-2 order-3 md:order-1 flex">
				<div class="flex flex-col justify-center md:justify-start py-4">
					<NuxtLink
						to="/"
						:aria-label="t('link.home')"
					>
						<UiIcon
							url="/images/logo.svg"
							class="shrink-0 self-stretch max-w-full w-[120px] xl:w-[224px] aspect-[3.33] text-white md:my-4"
						/>
					</NuxtLink>
					<div class="flex flex-col text-white text-xs md:text-sm mt-2">
						<span>© ТотДом, 2008-2024.</span>
						<span>Все права защищены.</span>
					</div>
				</div>
			</div>
			<div class="order-1 md:order-2 flex md:justify-center mt-2 py-4">
				<div class="flex flex-col gap-1 text-sm xl:text-base text-white font-medium uppercase">
					<NuxtLink
						:to="'/' + PathType.Villages"
					>
						{{ t('link.villages') }}
					</NuxtLink>
					<NuxtLink
						:to="'/' + PathType.Buy + '-' + PathType.House"
					>
						{{ t('link.houses') }}
					</NuxtLink>
					<NuxtLink
						:to="'/' + PathType.Buy + '-' + PathType.Plot"
					>
						{{ t('link.plots') }}
					</NuxtLink>
					<NuxtLink
						:to="'/' + PathType.Buy + '-' + PathType.Townhouse"
					>
						{{ t('link.townhouses') }}
					</NuxtLink>
					<NuxtLink
						:to="'/' + PathType.Developers"
					>
						{{ t('link.developers') }}
					</NuxtLink>
					<NuxtLink
						to="https://journal.totdom.com"
						target="_blank"
					>
						{{ t('link.journal') }}
					</NuxtLink>
				</div>
			</div>
			<div class="order-2 md:order-3 flex justify-end md:justify-center mt-2 py-4">
				<div class="flex flex-col gap-1 text-sm xl:text-base text-white font-medium uppercase">
					<NuxtLink
						:to="'/' + PathType.Documents"
					>
						{{ t('link.documents') }}
					</NuxtLink>
					<NuxtLink
						:to="'/' + PathType.Instruction"
					>
						{{ t('link.instruction') }}
					</NuxtLink>
				</div>
			</div>
		</div>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"link": {
			"home": "На главную",
			"villages": "Поселки",
			"houses": "Дома",
			"plots": "Участки",
			"townhouses": "Таунхаусы",
			"developers": "Застройщики",
			"journal": "Журнал",
			"documents": "Документы",
			"instruction": "Инструкция"
		}
	}
}
</i18n>