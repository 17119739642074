<script setup lang="ts">
defineProps({
	leadingIcon: {
		type: String,
		default: 'i-heroicons-plus-16-solid'
	}
})

const { t } = useI18n({
	useScope: 'local'
})

const authStore = useAuthStore()

const isOpenAuth = ref(false)
const isOpenRequest = ref(false)

const onAuth = () => {
	isOpenAuth.value = true
}

const onRequest = async () => {
	isOpenAuth.value = false
	isOpenRequest.value = true
}

const onOpen = () => {
	authStore.isLogged
		? onRequest()
		: onAuth()
}

</script>

<template>
	<UButton
		v-bind="$attrs"
		color="primary"
		class="mt-1"
		:leading-icon="leadingIcon"
		:label="t('button.request')"
		@click="onOpen"
		block
	/>
	<UiModal
		v-model="isOpenAuth"
		:fullscreen="$isMobile() && !$isTablet()"
	>
		<AuthForm
			:title="t('auth.title')"
			:callback="onRequest"
		/>
	</UiModal>
	<VillageRequestForm
		v-model:is-open="isOpenRequest"
	/>
</template>

<i18n lang="json">
{
	"ru": {
		"button": {
			"request": "Добавить поселок"
		},
		"auth": {
			"title": "Добавление поселка"
		}
	}
}
</i18n>