<script setup lang="ts">
import * as yup from 'yup'
import '~/libs/yup/yup-phone'
import { ServerStatusCode } from '~/enums/server'
import type { FormErrorEvent } from '#ui/types'

const props = defineProps({
	isOpen: {
		type: Boolean,
		required: true
	}
})

const emits = defineEmits([
	'update:isOpen'
])

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const localIsOpen = computed({
	get: () => props.isOpen,
	set: (value) => emits('update:isOpen', value)
})

const toast = useToast()
const feedbackStore = useFeedbackStore()

const TEXT_MIN = 3
const TEXT_MAX = 10000

const form = ref()
const isLoading = ref(false)

let schema = yup.object().shape({})
if (process.client) {
	schema = yup.object().shape({
		name: yup
			.string()
			.required(t('message.name.empty')),
		phone: yup
			.string()
			.phone('RU', false, t('message.phone.format'))
			.required(t('message.phone.empty')),
		email: yup
			.string()
			.email(t('message.email.format'))
			.required(t('message.email.empty')),
		text: yup
			.string()
			.min(TEXT_MIN, t('message.text.min', { min: TEXT_MIN }))
			.max(TEXT_MAX, t('message.text.max', { max: TEXT_MAX }))
			.required(t('message.text.empty')),
	})
}

const state = reactive({
	name: '',
	email: '',
	phone: '',
	text: ''
})

const getLength = (value: string | undefined, max: number) => {
	return (value?.length ?? 0) + '/' + max
}

const isValid = computed(() => schema.isValidSync(state))

const onSubmit = async () => {
	
	isLoading.value = true
	
	const result = await feedbackStore.send(state)
	
	isLoading.value = false
	
	const error = result.error?.value
	if (error) {
		onSubmitError(error)
		return
	}
	
	toast.add({
		id: 'error',
		title: t('message.success.title'),
		timeout: 2000,
		color: 'green'
	})
	
	localIsOpen.value = false
	
	state.text = '';
}

const onSubmitError = (error: any) => {
	const errors: any[] = []
	switch (error.statusCode) {
		case ServerStatusCode.UnprocessableContent:
			Object.entries(error.data.errors).forEach(([key, value]) => {
				errors.push({
					path: key,
					message: (value as Array<string>).join('')
				})
			})
			break
		case ServerStatusCode.ToManyRequests:
			toast.add({
				id: 'error',
				title: tg('error.title'),
				description: tg('error.many_requests', {
					seconds: tg('unit.sec_plural', parseInt((error.cause as any).response.headers.get('Retry-After')))
				}),
				timeout: 2000,
				color: 'red',
				icon: 'i-heroicons-exclamation-triangle-16-solid',
			})
			break
		default:
			if (error.value?.data?.message) {
				toast.add({
					id: 'error',
					title: tg('error.title'),
					description: error.value.data.message,
					timeout: 2000,
					color: 'red',
					icon: 'i-heroicons-exclamation-triangle-16-solid',
				})
			}
			break
	}
	
	if (errors.length) {
		form.value.setErrors(errors)
	}
}

const onError = (event: FormErrorEvent) => {
	const element = document.getElementById(event.errors[0].id)
	element?.focus()
	element?.scrollIntoView({
		behavior: 'smooth',
		block: 'center'
	})
}
</script>

<template>
	<UiModal
		v-model="localIsOpen"
		:fullscreen="$isMobile() && !$isTablet()"
		:center="false"
	>
		<div class="flex flex-col px-4 py-5 md:py-9 md:px-14">
			<div
				class="mb-4 text-center text-gray-600 text-xl font-semibold"
			>
				{{ t('title') }}
			</div>
			<div class="text-sm">
				{{ t('description') }}
			</div>
			<UForm
				ref="form"
				class="mt-4"
				:schema="schema"
				:state="state"
				:validate-on="['submit', 'change', 'input']"
				@submit="onSubmit"
				@error="onError"
			>
				<UFormGroup
					name="name"
					:label="t('field.name')"
					required
				>
					<UInput
						v-model="state.name"
						size="md"
						:placeholder="t('placeholder.name')"
						:ui="{
							form: '!bg-white'
						}"
					/>
				</UFormGroup>
				<UFormGroup
					name="phone"
					class="mt-4"
					:label="t('field.phone')"
					required
				>
					<UiPhoneInput
						v-model="state.phone"
					/>
				</UFormGroup>
				<UFormGroup
					name="email"
					class="mt-4"
					:label="t('field.email')"
					required
				>
					<UInput
						v-model="state.email"
						type="email"
						size="md"
						class="bg-white"
						:placeholder="t('placeholder.email')"
					/>
				</UFormGroup>
				<UFormGroup
					name="text"
					class="mt-4"
					:label="t('field.text')"
					required
				>
					<UTextarea
						v-model="state.text"
						size="md"
						:maxlength="TEXT_MAX"
						:maxrows="10"
						:placeholder="t('placeholder.text')"
						:ui="{
							form: '!bg-white !pr-16'
						}"
						autoresize
					/>
					<span
						class="absolute top-2 right-2.5 text-gray-400 text-sm"
						:class="[
							((state.text?.length ?? 0) >= TEXT_MIN ? 'text-teal-800' : 'text-gray-400')
						]"
					>
					{{ getLength(state.text, TEXT_MAX) }}
				</span>
				</UFormGroup>
				<div class="flex gap-2 items-center justify-center">
					<UButton
						type="submit"
						size="xl"
						class="mt-4"
						:label="t('button.submit')"
						:ui="{
							font: 'font-semibold',
							padding: {
								xl: 'px-3.5 py-3.5'
							}
						}"
						:disabled="isLoading || !isValid"
						block
					>
						<template #leading>
							<UIcon
								v-if="isLoading"
								name="i-svg-spinners-ring-resize"
								class="w-5 h-5"
								dynamic
							/>
						</template>
					</UButton>
				</div>
			</UForm>
		</div>
	</UiModal>
</template>

<i18n lang="json">
{
	"ru": {
		"title": "Быстрая связь",
		"description": "Здесь вы можете отправить любую информацию. Мы работаем ежедневно с 10 до 19-00 и отвечаем очень оперативно.",
		"field": {
			"name": "Имя",
			"phone": "Телефон",
			"email": "E-mail",
			"text": "Сообщение"
		},
		"placeholder": {
			"name": "Введите ваше имя",
			"phone": "Введите ваш телефон",
			"email": "Введите E-mail",
			"text": "Введите ваше сообщение"
		},
		"button": {
			"submit": "Отправить"
		},
		"message": {
			"success": {
				"title": "Ваше сообщение успешно отправлено."
			},
			"name": {
				"empty": "Вы не указали имя"
			},
			"phone": {
				"empty": "Вы не указали телефон",
				"format": "Вы ввели неверный формат телефона"
			},
			"email": {
				"empty": "Вы не указали E-mail",
				"format": "Вы ввели неверный формат E-mail"
			},
			"text": {
				"empty": "Вы не указали сообщение",
				"min": "Сообщение должно быть не меньше {min} символов",
				"max": "Сообщение должно быть не больше {max} символов"
			}
		}
	}
}
</i18n>