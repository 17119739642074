<script setup lang="ts">
import { PathType } from '~/enums'
import Feedback from '~/components/feedback/Feedback.vue'
import TopVillageRequest from '~/components/layout/TopVillageRequest.vue'

const { t } = useI18n({
	useScope: 'local'
})

const route = useRoute()
const authStore = useAuthStore()

const isOpenMenu = ref(false)
const isOpenMore = ref(false)
const isOpenFeedback = ref(false)

const isActiveMenuItem = (url: string) => route.path.startsWith(url)

const onOpenFeedback = () => {
	isOpenFeedback.value = true
}

watch(isOpenMenu, (newVal) => {
	newVal
		? document.body.classList.add('overflow-hidden')
		: document.body.classList.remove('overflow-hidden')
})

const menu = [{
	title: t('menu.villages'),
	to: '/' + PathType.Villages,
}, {
	title: t('menu.houses'),
	to: '/' + PathType.Buy + '-' + PathType.House,
}, {
	title: t('menu.plots'),
	to: '/' + PathType.Buy + '-' + PathType.Plot,
}] as any[]

const subMenu = [{
	title: t('menu.developers'),
	to: '/' + PathType.Developers
}, {
	title: t('menu.documents'),
	to: '/' + PathType.Documents,
}, {
	title: t('menu.journal'),
	to: 'https://journal.totdom.com',
	target: '_blank'
}, {
	title: t('menu.advertising'),
	to: '/advertising',
}] as any[]

</script>

<template>
	<header
		class="relative z-[100] flex justify-center items-center px-4 md:px-8 lg:px-16 py-0 w-full text-base bg-gray-600 shadow-lg max-md:max-w-full">
		<nav class="flex items-stretch justify-stretch w-full mx-auto max-w-8xl max-md:flex-wrap max-md:max-w-full text-base leading-normal _overflow-hidden">
			<NuxtLink
				to="/"
				:aria-label="t('link.home')"
			>
				<UiIcon
					url="/images/logo.svg"
					class="shrink-0 self-stretch max-w-full w-[120px] xl:w-[224px] aspect-[3.33] text-white my-4"
				/>
			</NuxtLink>
			<div class="hidden md:flex md:gap-2 md:px-4 xl:pl-10 xl:pr-4 flex-1 items-center">
				<NuxtLink
					v-for="item in menu"
					:key="item.to"
					:to="item.to"
					:target="item.target"
					class="relative flex items-center h-full text-white px-2 lg:px-4 xl:px-6 text-sm _lg:text-base max-w-36 text-center z-50"
					:class="{
						'cursor-default decoration-transparent bg-teal-800': isActiveMenuItem(item.to)
					}"
				>
					{{ item.title }}
				</NuxtLink>
				<div
					class="relative h-full"
					@mouseover="isOpenMore = true"
					@mouseleave="isOpenMore = false"
				>
					<div
						class="flex items-center h-full px-2 lg:px-4 xl:px-6 text-white text-sm max-w-36 text-center cursor-pointer hover:cursor-default hover:decoration-transparent hover:bg-teal-800"
						:class="[isOpenMore ? 'decoration-transparent bg-teal-800' : '']"
						style="cursor: pointer"
					>
						{{ t('menu.more') }}
					</div>
					<div
						class="absolute top-[100%] left-1/2 -translate-x-1/2 shadow-md overflow-hidden rounded-b bg-stone-50 transition-opacity duration-300 opacity-0"
						:class="[
							isOpenMore ? 'opacity-100' : 'opacity-0 pointer-events-none'
						]"
					>
						<div class="bg-stone-50 px-4 pt-5 pb-3">
							<AdButtonCreate
								size="sm"
								icon="i-heroicons-plus-16-solid"
								icon-class="hidden md:block md:mt-0 md:w-4 md:h-4 xl:w-5 xl:h-5"
								text-class="w-max"
								class="!px-4"
							/>
							<TopVillageRequest />
						</div>
						<div class="flex flex-col gap-[1px] mt-1">
							<NuxtLink
								v-for="item in subMenu"
								:key="item.to"
								:to="item.to"
								:target="item.target"
								class="relative flex px-4 py-3 bg-white text-teal-800 text-base"
								:class="{
									'cursor-default decoration-transparent font-medium': isActiveMenuItem(item.to)
								}"
							>
								{{ item.title }}
							</NuxtLink>
							<UButton
								variant="link"
								size="xl"
								class="bg-white rounded-none"
								:label="t('menu.feedback')"
								@click="onOpenFeedback"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="flex items-center gap-4 justify-center ml-auto">
				<LayoutTopMenuButton
					v-if="$isMobile() && !$isTablet()"
					v-model:open="isOpenMenu"
					class="justify-end"
				/>
				<template v-else>
					<div class="flex flex-col gap-[4px] text-white">
						<UButton
							v-if="!authStore.isLogged"
							variant="link"
							size="xs"
							class="block p-0 mr-2 self-end text-neutral-300 hover:text-neutral-300 focus:text-neutral-300"
							to="/cabinet/signin"
							:label="t('link.signin')"
						/>
						<UButton
							v-else
							variant="link"
							size="xs"
							class="block p-0 mr-2 text-right text-neutral-300 hover:text-neutral-300 focus:text-neutral-300"
							to="/cabinet"
							:label="t('link.cabinet')"
						/>
						<UButton
							variant="link"
							size="xs"
							class="block p-0 mr-2 text-right text-neutral-300 hover:text-neutral-300 focus:text-neutral-300"
							:label="t('link.feedback')"
							@click="onOpenFeedback"
						/>
						<form v-if="false" class="flex items-center gap-4 pr-2 pl-4 w-32 h-8 bg-neutral-50 rounded-full text-neutral-400">
							<label for="search" class="sr-only">{{ t('placeholder.search') }}</label>
							<input
								:placeholder="t('placeholder.search')"
								class="w-full my-auto bg-transparent text-xs border-none focus:outline-none placeholder-neutral-400"
							/>
							<button
								type="button"
								class="flex items-center justify-center shrink-0 aspect-square w-7 h-7"
								:aria-label="t('button.search')"
							>
								<UIcon name="i-app-search" class="w-4 h-4 text-neutral-500" />
							</button>
						</form>
					</div>
					<AdButtonCreate
						class="text-base font-medium w-10 h-10 lg:w-32 xl:w-48 xl:h-14 py-0 line-clamp-2 leading-tight"
					/>
				</template>
			</div>
		</nav>
	</header>
	<LayoutTopMenu
		v-model:open="isOpenMenu"
	/>
	<Feedback
		v-model:is-open="isOpenFeedback"
	/>
</template>

<i18n lang="json">
{
	"ru": {
		"menu": {
			"villages": "Поселки",
			"houses": "Дома",
			"plots": "Участки",
			"townhouses": "Таунхаусы",
			"developers": "Застройщики",
			"journal": "Журнал",
			"documents": "Документы",
			"feedback": "Обратная связь",
			"advertising": "Реклама на портале",
			"more": "Еще"
		},
		"link": {
			"home": "На главную",
			"feedback": "Быстрая связь",
			"signin": "Вход в кабинет",
			"cabinet": "Личный кабинет"
		},
		"placeholder": {
			"search": "Поиск..."
		},
		"button": {
			"search": "Найти"
		}
	}
}
</i18n>