export const useFeedbackStore = defineStore('feedback', {
  actions: {
    async send(data: any) {
      return useAPI('/v1/feedback', {
        method: 'POST',
        body: data,
      })
    },
  }
})
