<script setup lang="ts">
const props = defineProps({
	open: {
		type: Boolean,
		default: false
	}
})

const emits = defineEmits([
	'update:open'
])

const isOpen = computed({
	get: () => props.open,
	set: (value) => emits('update:open', value)
})

const onOpen = (e: any) => {
	isOpen.value = !isOpen.value
}
</script>

<template>
	<div
		class="text-white w-10 h-10 relative focus:outline-none cursor-pointer"
	>
		<div
			v-if="!isOpen"
			class="absolute inset-0 z-10"
			@click="onOpen"
		></div>
		<div class="absolute z-5 w-7 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
	    <span
		    aria-hidden="true"
		    class="block absolute w-7 h-[3px] rounded-full bg-current transform transition duration-300 ease-in-out"
		    :class="{
					'rotate-45': isOpen,
					'-translate-y-[7px]': !isOpen
			}" />
			<span
				aria-hidden="true"
				class="block absolute w-7 h-[3px] rounded-full bg-current transform transition duration-300 ease-in-out"
				:class="{
				'opacity-0': isOpen
			}" />
			<span
				aria-hidden="true"
				class="block absolute w-7 h-[3px] rounded-full bg-current transform transition duration-300 ease-in-out"
				:class="{
				'-rotate-45': isOpen,
				'translate-y-[7px]': !isOpen
			}" />
		</div>
	</div>
</template>